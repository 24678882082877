var getHostnameAndPath = function (url) { return "".concat(url.hostname).concat(url.pathname); };
var determineActualUrl = function (dlUrl, wdnUrl) {
    var windowUrl = new URL(wdnUrl);
    if (!dlUrl)
        return windowUrl;
    var dataLayerUrl = new URL(dlUrl);
    if (getHostnameAndPath(dataLayerUrl) === getHostnameAndPath(windowUrl)) {
        return dataLayerUrl;
    }
    return windowUrl;
};
export default determineActualUrl;
