var getSecondLevelDomain = function () {
    var regexp = document.location.hostname.match('[^.]+[.][^.]+$');
    if (regexp !== null) {
        return regexp[0];
    }
    if (document.location.hostname === 'localhost')
        return 'localhost';
    return null;
};
export default getSecondLevelDomain;
