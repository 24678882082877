import { Promise } from '../../../lib/';
var postTracking = function (url, payload) {
    var xhr = new XMLHttpRequest();
    return new Promise(function (resolve, reject) {
        xhr.onreadystatechange = function () {
            if (xhr.readyState !== 2)
                return;
            if (xhr.status >= 200 && xhr.status < 300) {
                resolve();
            }
            else {
                reject(new Error("".concat(xhr.status, " ").concat(xhr.statusText)));
            }
        };
        xhr.open('POST', url, true);
        xhr.withCredentials = true;
        /**
         * Use Content-type header 'text/plain' in order to mimic a simple CORS request
         * that does not require a preflight request
         * see https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS#Simple_requests
         */
        xhr.setRequestHeader('Content-Type', 'text/plain');
        xhr.send(JSON.stringify(payload));
    });
};
export default postTracking;
