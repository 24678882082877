var applicationDomainMap = {
    wbm: 'xing.com',
    kun: 'kununu.com',
    leb: 'lebenslauf.com',
    onf_one: 'onlyfy.io',
};
var getApplicationByDomain = function (secondLevelDomain) {
    var matchedEntry = Object.entries(applicationDomainMap).find(function (_a) {
        var app = _a[0], domain = _a[1];
        return domain === secondLevelDomain;
    });
    return matchedEntry ? matchedEntry[0] : '';
};
export default getApplicationByDomain;
