import { Future, publisher, subscriber } from '../lib/';
import createFrame from './createFrame';
var createSandbox = function (window) {
    var withFrame = new Future();
    var withHandler = new Future();
    var sandbox = {};
    sandbox.load = function (url) {
        return createFrame(window, url).then(function (iframe) {
            withFrame.resolve(iframe);
            var match = url.match(/(https?:\/\/[^/&?]+)/, '');
            var origin = match ? match[1] : '*';
            var publish = publisher(window, iframe.contentWindow, origin);
            var subscribe = subscriber(window, '*');
            var handler = {
                iframe: iframe,
                publish: publish,
                subscribe: subscribe,
            };
            withHandler.resolve(handler);
            return iframe;
        });
    };
    sandbox.publish = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return withHandler.then(function (handler) {
            return handler.publish.apply(handler, args);
        });
    };
    sandbox.subscribe = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return withHandler.then(function (handler) {
            return handler.subscribe.apply(handler, args);
        });
    };
    sandbox.unload = function (event, props) {
        return (withFrame &&
            withFrame.then(function (iframe) {
                window.document.body.removeChild(iframe);
                withFrame = null;
            }));
    };
    return sandbox;
};
export default createSandbox;
