var console = global.console;
var apply = Function.prototype.apply;
var polyfill = {};
var nesting = 0;
[
    'log',
    'debug',
    'info',
    'warn',
    'error',
    'group',
    'groupCollapsed',
    'groupEnd',
].forEach(function (name) {
    polyfill[name] = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        if (!console) {
            return;
        }
        if (!console.group) {
            // emulate group logging
            if (name === 'group') {
                nesting += 1;
            }
            else if (name === 'groupEnd') {
                nesting -= 1;
                // skip actual logging
                return;
            }
            else if (nesting > 0) {
                for (var i = 0; i < nesting; i++) {
                    args.unshift('  ');
                }
            }
        }
        var method = console[name] || console.log;
        // NOTE: For IE we need to do this in order to be able to apply our arguments!
        method && apply.call(method, console, args);
    };
});
export default console && console.group ? console : polyfill;
export { polyfill };
