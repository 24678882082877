var API_VERSION = 'v0';
var TTT_VERSION = '0.0.0';
export default (function (constants) {
    API_VERSION = constants.API_VERSION;
    TTT_VERSION = constants.TTT_VERSION;
});
var PARAM_REGEX = /^(sc_|fbclid|gclid|msclkid)/;
var CLIENTSIDE_VISITORCOOKIE_NAME = 'csod_visitor_id';
var CLIENTSIDE_VISITORCOOKIE_DOMAIN_ALLOWLIST = ['csod.com', 'localhost'];
var DEDUPLICATION_ID_ALLOWLIST = ['wbm'];
var ABACUS_TRACKING_PATH = '/api/upgraded-umbrella/r';
var NWT_TRACKING_PATH = '/api/unified-underdog';
export { API_VERSION, TTT_VERSION, PARAM_REGEX, CLIENTSIDE_VISITORCOOKIE_DOMAIN_ALLOWLIST, CLIENTSIDE_VISITORCOOKIE_NAME, DEDUPLICATION_ID_ALLOWLIST, ABACUS_TRACKING_PATH, NWT_TRACKING_PATH, };
