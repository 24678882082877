var _a;
import { DEBUG, INFO, WARN, ERROR } from './constants';
import { getMinimumLogLevel, setMinimumLogLevel } from './helpers';
import console, { polyfill } from './console';
var logLevels = (_a = {},
    _a[DEBUG] = 0,
    _a[INFO] = 1,
    _a[WARN] = 2,
    _a[ERROR] = 3,
    _a);
var canLog = function (logLevel) {
    var minLevel = getMinimumLogLevel();
    return !(!minLevel || logLevels[logLevel] < logLevels[minLevel]);
};
var logMessage = function (prefix, logLevel, method) {
    var args = [];
    for (var _i = 3; _i < arguments.length; _i++) {
        args[_i - 3] = arguments[_i];
    }
    if (!canLog(logLevel)) {
        return;
    }
    if (logLevel === ERROR) {
        console[method].apply(console, args);
    }
    else {
        var message = prefix +
            ' ' +
            args.map(function (a) { return (typeof a === 'object' ? JSON.stringify(a) : a); }).join(' ');
        console[method](message);
    }
};
function createLogger(name, minLevel) {
    if (minLevel === void 0) { minLevel = DEBUG; }
    var prefix = "".concat(name, ":");
    var logger = {};
    Object.keys(polyfill).forEach(function (method) {
        var logLevel = logLevels[method] !== undefined ? method : INFO;
        logger[method] = logMessage.bind(logger, prefix, logLevel, method);
    });
    logger.inspect = function (logLevel, message, object) {
        if (!canLog(logLevel)) {
            return;
        }
        logger.group(message);
        Object.keys(object)
            .filter(function (key) { return typeof object[key] !== 'function'; })
            .sort()
            .forEach(function (key) {
            console.log("".concat(key, ": ").concat(JSON.stringify(object[key])));
        });
        logger.groupEnd();
    };
    logger.prefix = prefix;
    return logger;
}
export default createLogger;
export { logLevels, logMessage, setMinimumLogLevel };
