function getQueryParams(url) {
    if (!url) {
        return [];
    }
    url = url.substr(url.indexOf('?'));
    var regex = /[?&]([^=?&#]+)(?:=([^?&#]*))?/g;
    var params = [];
    var match;
    while ((match = regex.exec(url)) !== null) {
        var key = match[1];
        var value = match[2];
        params.push([decodeURIComponent(key), value && decodeURIComponent(value)]);
    }
    return params;
}
export default getQueryParams;
