import { Promise } from '../lib/';
var createFrame = function (window, url) {
    return new Promise(function (resolve) {
        var document = window.document;
        var width = window.innerWidth || 0;
        var height = window.innerHeight || 0;
        var iframe = document.createElement('iframe');
        iframe.setAttribute('id', 'stm');
        iframe.setAttribute('sandbox', 'allow-scripts allow-same-origin allow-forms');
        iframe.setAttribute('style', "top:-".concat(height, "px;left:-").concat(width, "px;width:").concat(width, "px;height:").concat(height, "px;border:0;position:absolute;visibility:hidden;opacity:0"));
        iframe.onload = function () { return resolve(iframe); };
        // iframe.onerror = (error) => reject(error);
        iframe.setAttribute('src', url);
        var attachFrame = function () {
            var body = document.body;
            body.insertBefore(iframe, body.childNodes[0]);
        };
        if (document.body) {
            attachFrame();
        }
        else {
            document.addEventListener('DOMContentLoaded', attachFrame, true);
        }
    });
};
export default createFrame;
