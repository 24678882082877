var tryParseMessage = function (data) {
    // ignore non JSON messages
    if (typeof data !== 'string' || data.indexOf('{') !== 0) {
        return false;
    }
    try {
        return JSON.parse(data);
    }
    catch (error) {
        return false;
    }
};
export default tryParseMessage;
