import { getQueryParams, mergeQueryParams } from '../lib/';
export var removeParams = function (search, filter) {
    var params = getQueryParams(search);
    var cleanedParams = params.filter(function (_a) {
        var key = _a[0], value = _a[1];
        return filter(key, value) === false;
    });
    return mergeQueryParams(cleanedParams);
};
export default (function (filter) {
    var _a = window.location, href = _a.href, origin = _a.origin, pathname = _a.pathname, search = _a.search, hash = _a.hash;
    if (!search)
        return href;
    var newSearch = removeParams(search, filter);
    if (newSearch === search) {
        return href;
    }
    var newHref = newSearch && newSearch !== '?'
        ? "".concat(origin).concat(pathname).concat(newSearch).concat(hash)
        : "".concat(origin).concat(pathname).concat(hash);
    window.history.replaceState({}, null, newHref);
    return newHref;
});
