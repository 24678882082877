import isNode from 'detect-node';
import setupTracking from './src/setupTracking';
import createMock from './src/createMock';
import { API_VERSION, TTT_VERSION } from './version';
import define from './constants';
define({
    API_VERSION: API_VERSION,
    TTT_VERSION: TTT_VERSION,
});
var ttt = isNode ? createMock() : setupTracking(global.window);
export default ttt;
