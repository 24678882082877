import createLogger from './logging/createLogger';
import { DEBUG } from './logging/constants';
var logger = createLogger('xhr');
var fetchJSON = function (url, options) {
    if (options === void 0) { options = {}; }
    return new Promise(function (resolve, reject) {
        var _a = options.method, method = _a === void 0 ? 'GET' : _a, params = options.params;
        var withData = params !== undefined;
        logger.inspect(DEBUG, "".concat(method, " ").concat(url, " request"), params || {});
        var xhr = new XMLHttpRequest();
        xhr.open(method, url, true);
        xhr.withCredentials = true;
        if (withData) {
            xhr.setRequestHeader('Content-Type', 'application/json');
        }
        xhr.onerror = reject;
        xhr.onload = function () {
            var data = JSON.parse(xhr.responseText);
            logger.inspect(DEBUG, "".concat(method, " ").concat(url, " response"), data || {});
            resolve(data);
        };
        xhr.send(JSON.stringify(params));
    });
};
export default fetchJSON;
