import { buildMessage } from './helpers';
import tryParseMessage from './tryParseMessage';
var subscriber = function (window, origin) {
    if (origin === void 0) { origin = '*'; }
    return function (action, callback, options) {
        if (options === void 0) { options = {}; }
        var handler = function (e) {
            // ignore wrong origins
            if (origin !== '*' && e.origin !== origin) {
                return false;
            }
            var message = tryParseMessage(e.data);
            if (!message || message.action !== action) {
                return false;
            }
            var fullResponse = options.fullResponse;
            var id = message.id;
            var promise = new Promise(function (resolve, reject) {
                try {
                    var result = callback(fullResponse ? message : message.payload);
                    resolve(result);
                }
                catch (error) {
                    reject(error);
                }
            });
            if (id && e.source && action.indexOf('response:') !== 0) {
                promise
                    .then(function (result) { return [null, result]; })
                    .catch(function (error) { return [(error && error.message) || 'error', null]; })
                    .then(function (_a) {
                    var error = _a[0], payload = _a[1];
                    var response = buildMessage({
                        id: id,
                        action: "response:".concat(action, ":").concat(id),
                        error: error,
                        payload: payload,
                    });
                    e.source.postMessage(JSON.stringify(response), '*');
                });
            }
            return true;
        };
        window.addEventListener('message', handler, false);
        return function () {
            window.removeEventListener('message', handler, false);
        };
    };
};
export default subscriber;
