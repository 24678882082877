var getPerformanceMetrics = function (window) {
    var performance = window.performance ||
        window.webkitPerformance ||
        window.mozPerformance ||
        window.msPerformance;
    if (!performance || !performance.timing) {
        return {};
    }
    var timing = performance.timing;
    var now = new Date().getTime();
    var times = {
        network: timing.responseEnd || now,
        pageLoad: timing.domContentLoadedEventEnd || now,
        pageSetup: timing.loadEventStart || now,
        loadEvent: now,
    };
    var prevTime = timing.navigationStart;
    var deltas = Object.keys(times)
        .map(function (name) {
        var _a;
        var time = times[name];
        var delta = time - prevTime;
        prevTime = time;
        return _a = {},
            _a[name] = delta,
            _a;
    })
        .reduce(function (prev, current) { return Object.assign(prev, current); }, {});
    return deltas;
};
export default getPerformanceMetrics;
