function mergeQueryParams(params) {
    var entries = params.map(function (_a) {
        var key = _a[0], value = _a[1];
        return [key, value]
            .filter(function (e) { return e !== undefined; })
            .map(function (e) { return encodeURIComponent(e); })
            .join('=');
    });
    return "?".concat(entries.join('&'));
}
export default mergeQueryParams;
