var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var setCookie = function (name, value, ttl, options) {
    if (ttl === void 0) { ttl = null; }
    if (options === void 0) { options = {}; }
    if (typeof ttl === 'number') {
        var date = new Date();
        date.setTime(date.getTime() + ttl);
        options.expires = date.toUTCString();
    }
    options = __assign({ path: '/', SameSite: 'Lax; Secure' }, options);
    var optionsString = Object.keys(options)
        .map(function (key) { return "".concat(key, "=").concat(options[key]); })
        .join(';');
    document.cookie = "".concat(encodeURIComponent(name), "=").concat(encodeURIComponent(value), ";").concat(optionsString);
};
var getCookie = function (name) {
    var pattern = "".concat(encodeURIComponent(name), "=");
    var cookies = document.cookie.split(';').map(function (c) { return c.trim(); });
    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        if (cookie.indexOf(pattern) === 0) {
            return decodeURIComponent(cookie.substring(pattern.length, cookie.length));
        }
    }
    return null;
};
export { setCookie, getCookie };
