var Future = /** @class */ (function () {
    function Future() {
        this.reset();
    }
    Future.prototype.reset = function () {
        var _this = this;
        this.resolved = false;
        this._promise = new Promise(function (resolve) {
            _this.resolve = function (value) {
                resolve(value);
                _this.resolved = true;
            };
        });
    };
    Future.prototype.resetAndResolve = function (value) {
        this.resolved && this.reset();
        this.resolve(value);
    };
    Future.prototype.then = function (value) {
        return this._promise.then(value);
    };
    return Future;
}());
export default Future;
